.plan {
  .plan-container {
    height: 100%;
    background-color: var(--ion-color-light);
    display: flex;
    justify-content: center;
  }

  .plan-details {
    width: 100%;
    height: 100%;
    background: var(--ion-background-color);
    padding: 16px;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 10px;

    .plan {
      display: flex;
      flex-direction: column;
      gap: 10px;  
    }

    .subheading {
      font-weight: 600;
    }

    .plan-title {
      font-size: large;
      font-weight: 900;
      color: var(--ion-color-dark);
    }

    .plan-price {
      font-size: medium;
      font-weight: 600;
      color: var(--ion-color-medium);
    }

    .commission-rate {
      font-size: 2em;
    }
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {
  .plan-details {
    padding: 40px;
  }
  
  .plan-details.landscape {
    padding: 40px;
    width: 70%;
  }

  .plan-details.portrait {
    width: 80%;
  }
}