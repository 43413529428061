div.partner-form,
form.partner-form {
  width: 100%;
  min-height: 100%;
  padding: 20px 20px 10px 20px;
	display: flex;
  flex-direction: column;
	gap: 10px;
  overflow: auto;
  background: var(--ion-background-color);

  .outer-form-container {
    flex-grow: 1;
  }

  .form-container {
    display: grid;
    gap: 10px;
    grid-template-rows: 1fr auto;

    .ant-radio-group {
      .ant-radio-button-wrapper:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      .ant-radio-button-wrapper:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }
    }

    .ant-input-affix-wrapper {
      border: none;
      padding: 10px 20px;
      background: var(--ion-color-light) !important;
      border-radius: 10px;
    }

    .ant-input-number {
      border: none;
      background: var(--ion-color-light);
      border-radius: 10px;

      .ant-input-number-input-wrap {
        padding: 5px;
      }
    }

    .phone-input {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    
    .phone-input {
      .ant-input-group-addon {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        background: var(--ion-color-light);
        border: 1px solid var(--ion-color-light-shade);
        border-right-width: 1px;
      }

      .ant-input {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px; 
      }
    }

    .ant-form-item-label {
      text-align: left;
    }
    
    .ant-form-item {
      margin-bottom: 10px;
      flex-flow: wrap;
      text-align: left;
      align-items: center;
    }

    .location-note {
      margin: 10px 0;
    }

    .hide-location {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      gap: 10px;
      justify-content: flex-end;
      padding: 10px 10px 0 0;

      .hide-location-label {
        text-align: right;
      }

      .hide-location-info{
        grid-column: 1/3;
        text-align: right;
      }
    }

    .cover-image-upload .ant-upload-list > img {
      object-fit: contain;
    }
    
    .cover-image-upload > .ant-upload-list {
      display: grid;
    }
    
    .cover-image-upload .ant-upload-list-picture-card-container {
      width: 100%;
      height: 10rem;
    }
    
    .cover-image-upload .ant-upload-list-item-image {
      object-fit: cover !important;
    }
    
    .cover-image-upload .ant-upload.ant-upload-select-picture-card {
      width: 100%;
      height: 48px;
      margin: 0px;
    }

    .cover-photo-guidlines {
      padding: 10px;
      margin-top: 10px;
      background: var(--ion-color-light);
      border-radius: 10px;
      display: grid;
      gap: 10px;

      img {
        max-width: 300px;
        height: auto;
      }

      @media (min-width:961px)  {
        grid-template-columns: 1fr 1fr;
      }

      .example-photo {
        display: grid;
        gap: 5px;
      }

      ul {
        margin-bottom: 0;
      }
    }

    .field-description {
      font-size: 12px;
      color: var(--ion-color-medium);
      padding: 10px 0;
    }

    .advance-order-days > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content,
    .prep-time > .ant-form-item-row > .ant-form-item-control > .ant-form-item-control-input > .ant-form-item-control-input-content {
      display: grid;
      grid-template-columns: 100px 1fr;
      row-gap: 10px;
      align-items: center;
    }

    .prep-time-mins {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: center;
    }

    .prep-time,
    .advance-order-days {
      .ant-form-item-control-input-content {
        .ant-form-item {
          margin-bottom: 0px;
        }
      }

      .value-info {
        grid-column: 1/3;
        font-size: 0.8em;
        color: var(--ion-color-medium);
      }
    }

    .day-checkbox {
      display: grid;
      grid-template-columns: repeat(auto-fill, 70px);
    }

    .pickup-prepartion-time,
    .delivery-prepation-time {
      .ant-form-item-control-input-content > div {
        display: flex;
        align-items: center;
      }
    }

    .cutoff-desc-time,
    .cutoff-desc-advance-duration {
      font-weight: bold;
      color: var(--ion-color-primary);
      text-decoration: underline;
    }

    .delivery-form-area-tags {
      padding: 10px 0px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .delivery-form-area-tags .ant-tag {
      margin-right: 5px;
      margin-top: 5px;
    }
    
    .info-label {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 20px 0px;

      .info-text {
        display: flex;
        flex-direction: column;
      }

      .info-icon {
        font-size: 1.4em;
        color: var(--antd-primary-color);
      }
    }

    .delivery-fee {
      .ant-form-item-control-input-content {
        display: grid;
        gap: 10px;
      }

      .ant-btn {
        margin: 10px 0; 
      }

      .delete-btn {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;
      }
    }

    .monetary-input {
      gap: 5px;
      display: flex;
      align-items: center;
      
      .ant-input-number {
        width: 65px;

        input {
          padding: 0 5px;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .add-tier-btn {
      margin: 20px 0px 10px;
    }

    .delivery-areas {
      display: grid;
      gap: 20px;

      @media (min-width:768px)  {
        grid-template-columns: 1fr 1fr;
      }
    }

    .day-timing {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }

  .menu-tabs {
    overflow: auto;
  }

  .payment-setup {
    display: grid;
    gap: 10px;
    text-align: left;
    overflow: hidden;

    .stripe-partner-title {
      font-size: xx-large;

      .stripe-logo {
        height: 1.6em;
        display: inline-flex;
        vertical-align: middle;
      }
    }

    .stripe-btn {
      background-color: #7069fe;
    }

    .payment-onboarding-check {
      display: flex;
      flex-direction: column;
      padding: 20px 0px;
      gap: 5px;
      text-align: center;
    }
  
    .payment-onboarding-complete {
      display: grid;
      gap: 20px;
      justify-items: center;
      font-size: medium;
    }

    .setup-btn {
      margin: auto;
    }
  }

  .form-buttons {

    .ant-form-item-control {
      display: flex;
      justify-content: flex-end;
    }

    .right-btn,
    .left-btn {
      margin: 0px 5px;
    }

    .right-btn {
      float: right;
    }
    
    .left-btn {
      float: left;
    }
  }

  .error-text {
    color: var(--ion-color-danger);
    font-weight: 600;
  }
}

div.activation-page {
	height: 100%;
  padding: 20px 20px 10px 20px;
	display: grid;
	grid-template-rows: 1fr auto;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: center;
	text-align: center;
	gap: 10px;

  .activation-info {
    display: grid;
    gap: 10px;
    font-size: medium;

    .title {
      font-size: xx-large;
    }
  }

  .submit-btn {
    margin: 20px auto;
  }

  .form-buttons {
    margin-bottom: 24px;
  }
}

.menu-form-container {
  width: 100%;
  height: 100%;
  overflow: auto;

  &.menu-modal-view {
    .menu-conteont {
      .menu-categories {
        height: 620px;
      }
    }
  }

  form.partner-form.menu-form {
    min-height: 100%;
    height: auto;
    margin: auto;

    .ant-tabs {
      overflow: hidden;
      flex-grow: 1;
    }
  
    .ant-tabs-content-holder {
      display: grid;
      grid-template-rows: 100%;

      .ant-tabs-content {
        height: 100%;

        .ant-tabs-tabpane {
          height: 100%;
        }
      }
    }

    .menu-categories {
      &.full-menu {
        max-height: 1050px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {
  @media (orientation: landscape) {
    div.partner-form,
    form.partner-form {
      max-width: 70%;
      margin: 0 auto;
    }

    form.partner-form.menu-form {
      max-width: 90%;
      margin: auto;
    }
  }

  @media (orientation: portrait) {
    div.partner-form,
    form.partner-form {
      width: 80%;
    }
  }
}

.prep-time-info,
.adv-order-info {
  .example-heading {
    font-weight: 600;
    color: var(--ion-text-color);
  }
}

.adv-order-info {
  .example-adv-short {
    padding-top: 10px;
  }

  .example-adv-calendar {
    padding: 10px;

    .day {
      border-radius: 5px;
      
      &.today {
        background-color: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        margin: 0px 10px;
      }
  
      &.advance {
        background-color: var(--ion-color-success);
        color: var(--ion-color-success-contrast);
        margin: 0px 10px;
      }
    }
  }

  .example-adv-label {
    text-align: center;
    font-size: 0.8em;
    color: var(--ion-color-medium);
  }
}

.prep-time-info {
  color: var(--ion-color-dark);

  .example-prep-days {
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 0 20px;

    .day-marker {
      display: grid;
      gap: 10px;
      justify-items: center;

      .day-indicator {
        width: 1.6em;
        height: 1.6em;
        border-radius: 0.8em;
        background: var(--ion-color-success);
      }
    }

    .day-arrow {
      display: grid;
      justify-items: center;
      justify-content: center;
      font-size: 0.8em;
      grid-template-columns: 80%;

      .arrow {
        width: 100%;
      }
      
      .line {
        margin-top: 8px;
        width: calc(100% - 16px);
        background: var(--ion-color-success);
        height: 4px;
        float: right;
      }
      
      .point {
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-right: 16px solid var(--ion-color-success);
        float: left;
      }
    }
  }

  .ant-slider.example-prep-hours {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  
    .ant-slider-rail {
      background-color: var(--ion-color-warning);
    }
  
    .ant-slider-mark-text {
      white-space: nowrap;
    }
  
    .prep-cutt-off {
      display: grid;

      .label {
        font-size: 0.8em;
      }
    }
  
    .ant-slider-dot:first-child {
      background-color: var(--ion-color-success);
      border: var(--ion-color-success);
    }
  
    .ant-slider-dot:last-child {
      background-color: var(--ion-color-warning);
      border: var(--ion-color-warning);
    }
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width:961px)  {  
  .ant-picker-calendar.example-adv-calendar {
    padding: 10px 100px;
  }
}